<template>
  <backoffice-container>
    <div class="justify-content-md-center">
      <div v-if="isLoading" class="mb-5">
        <b-spinner
          type="grow"
          small
          variant="primary"
          class="mx-auto mt-3 d-block"
        />
      </div>
      <div v-else>
        <b-card class="mx-auto w-75">
          <h4 class="mb-2">
            {{ $t('backoffice.feedback.name') }}
          </h4>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <div class="pt-1 d-flex align-items-center">

              <user-avatar :user="suggestion.ownedByMember" size="40" variant="light-primary" />
              <!-- Author and date -->
              <div class="ml-1">
                <p class="font-weight-extrabold mb-0">
                  {{ suggestion.ownedByMember.name }} {{ suggestion.ownedByMember.surname }}
                </p>
                <small class="text-muted mb-0">
                  {{ date(suggestion.createdAt) }}
                </small>
              </div>
            </div>
            <div>
              <b-badge :variant="resolveStatusVariant(suggestion.status)">
                {{ getStatus(suggestion.status) }}
              </b-badge>
            </div>
          </div>
          <p v-if="suggestion.message" class="mt-1">
            {{ suggestion.message }}
          </p>
        </b-card>
        <b-card class="mx-auto w-75">
          <h4 class="mb-1">
            {{ $tc('backoffice.feedback.response', 0) }}
          </h4>
          <template v-if="suggestion.answers && suggestion.answers.length > 0">
            <span v-for="(answer, index) in answers" :key="index">
              <b-card>
                <div class="w-100 d-flex align-items-center justify-content-between">
                  <div class="pt-1 w-100 d-flex align-items-center ">
                    <user-avatar :user="answer.responsedBy" size="40" variant="light-primary" />
                    <!-- Author and date -->
                    <div class="ml-1">
                      <p class="font-weight-extrabold mb-0">
                        {{ answer.responsedBy.name }} {{ answer.responsedBy.surname }}
                      </p>
                      <small class="text-muted mb-0">
                        {{ date(answer.responsedAt) }}
                      </small>
                    </div>
                  </div>
                  <b-badge :variant="resolveStatusVariant(answer.status, 'response')">
                    {{ getStatus(answer.status) }}
                  </b-badge>
                </div>
                <p v-if="answer" class="mt-1">
                  {{ answer.answer[locale] || Object.values(answer.answer)[0] }}
                </p>
              </b-card>
            </span>
          </template>
          <b-row v-else class="horizontal-placeholder flex-column justify-content-center">
            <b-col cols="12">
              <img :src="placeholder" class="d-block mx-auto">
            </b-col>
            <b-col cols="12">
              <p class="text-primary">
                {{ $t('backoffice.feedback.placeholder-text', { item: $tc('backoffice.feedback.response', 0)}) }}
              </p>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="mx-auto w-75">
          <h5 class="mb-2">
            {{ $t('backoffice.feedback.response-title') }}
          </h5>
          <b-form class="form-challenge-idea my-2" @submit.prevent="handleCreateAnswer">
            <b-form-group class="flex-grow-1">
              <label for="suggestionType" class="font-weight-bold">
                {{ $t('backoffice.feedback.status-title') }}:</label>
              <v-select
                v-model="status"
                name="suggestionType"
                :searchable="false"
                :clearable="false"
                :options="typeOptions"
              />
            </b-form-group>
            <label for="suggestionType" class="font-weight-bold"> {{ $tc('backoffice.feedback.response', 1) }}*:</label>
            <!-- <quill-editor v-model="answer" class="mb-2 w-100 float-left" /> -->
            <b-form-textarea v-model="answer[locale]" :placeholder="$t('form.type-placeholder')" rows="10" />
            <div class="float-right my-2">
              <b-button variant="primary" type="submit">
                {{ $t('form.actions.save') }}
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </backoffice-container>
</template>

<script>
import {
  BBadge,
  BAvatar,
  BCard,
  BIconChatText,
  BLink,
  BRow,
  BButton,
  BForm,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue';
import draggable from 'vuedraggable';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import { quillEditor } from 'vue-quill-editor';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import vSelect from 'vue-select';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import UserAvatar from '@core/components/user/UserAvatar.vue';

export default {
  name: 'Feedback',
  components: {
    BBadge,
    BAvatar,
    draggable,
    BackofficeContainer,
    BCard,
    BIconChatText,
    BLink,
    BRow,
    BButton,
    quillEditor,
    BForm,
    BFormGroup,
    vSelect,
    BFormTextarea,
    UserAvatar,
    BSpinner,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      status: '',
      answer: {},
      isLoading: false,
    };
  },
  computed: {
    suggestions() {
      return this.$store.getters.feedback;
    },
    typeOptions() {
      return [
        this.$t('backoffice.feedback.status.to-do'),
        this.$t('backoffice.feedback.status.in-progress'),
        this.$t('backoffice.feedback.status.in-review'),
        this.$t('backoffice.feedback.status.done'),
      ];
    },
    placeholder() {
      return Placeholder;
    },
    /* item() {
      return this.$tc('backoffice.feedback.response', 0);
    }, */
    item() {
      return this.$store.getters.item('feedback', this.$route.params.id);
    },
    suggestion() {
      return this.item;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    answers() {
      const allAnswers = this.suggestion.answers;
      return allAnswers.reverse();
    },
  },
  watch: {},
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.status = this.getStatus(this.suggestion.status);
    this.isLoading = false;
  },
  methods: {
    resolveTagVariant(tag) {
      if (tag === 'bug') {
        return 'danger';
      }
      if (tag === 'feature_improvement') {
        return 'info';
      }
      return 'success';
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleString('en-GB');
    },
    async fetchData(forceAPICall = false) {
      await this.$store.dispatch('getItems', {
        itemType: 'backoffice/feedback',
        customName: 'feedback',
        forceAPICall,
        page: 1,
      });
      this.isLoading = false;
      this.updateBreadcrumbs();
    },
    getSuggestionLocation(task) {
      return {
        name: 'feedback-details',
        params: { id: task.key, communityId: this.$route.params.communityId },
      };
    },
    getTypeText(tag) {
      return tag === 'bug'
        ? this.$t('backoffice.feedback.type-options.bug')
        : tag === 'feature_improvement'
          ? this.$t('backoffice.feedback.type-options.improvement')
          : this.$t('backoffice.feedback.type-options.query');
    },
    async handleCreateAnswer() {
      const statusResponse = this.getResponseStatus();
      this.suggestion.status = statusResponse;
      try {
        await this.$store.dispatch('addSuggestionAnswer', {
          status: statusResponse,
          answer: this.answer,
          suggestionKey: this.$route.params.id,
        });
        this.notifySuccess(this.$t('backoffice.feedback.messages.success'));
        this.answer = {};
        this.fetchData(true);
      } catch (error) {
        this.notifyError(this.$t('backoffice.feedback.messages.error'));
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: 'backoffice.feedback.title',
          to: { name: 'feedback' },
        },
        {
          text: 'backoffice.feedback.suggestion-detail',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    getAnswers() {
      this.suggestion.answer = [];
      this.suggestion.map((item) => (item.answer !== '' && item.answer !== null ? this.suggestion.answer.push(item.answer) : null));
      return this.suggestion.answer.reverse();
    },
    getStatus(status) {
      const statusTranslated = {
        'To do': this.$t('backoffice.feedback.status.to-do'),
        'In progress': this.$t('backoffice.feedback.status.in-progress'),
        'In review': this.$t('backoffice.feedback.status.in-review'),
        Done: this.$t('backoffice.feedback.status.done'),
      };
      return statusTranslated[status];
    },
    getResponseStatus() {
      if (this.status === this.$t('backoffice.feedback.status.to-do')) {
        return 'To do';
      }
      if (this.status === this.$t('backoffice.feedback.status.in-progress')) {
        return 'In progress';
      }
      if (this.status === this.$t('backoffice.feedback.status.in-review')) {
        return 'In review';
      }
      return 'Done';
    },
    resolveStatusVariant(status, response) {
      // eslint-disable-next-line no-param-reassign
      status = response ? status : this.getResponseStatus();
      if (status === 'To do') {
        return 'success';
      }
      if (status === 'In review') {
        return 'warning';
      }
      if (status === 'In progress') {
        return 'primary';
      }
      return 'secondary';
    },
    date(date) {
      return this.$moment(date)
        .locale(this.locale)
        .fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
.answer {
  img {
    max-width: 500px;
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/pages/app-todo.scss';
</style>
